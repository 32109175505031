// handles confirmation and export downloads

import React, { useState, useEffect } from 'react';
import { updateData } from './firestoreService';
import { documentExport } from './documentExport';
import { exportPDF } from './exportPDF';

const ConfirmDocument = ({ documentData, documentType, patientId, companyId, dataHistory, docId, documentComplete, personName }) => {
    const [confirmationSection, setConfirmationSection] = useState(null);
    const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
    const [isTextAreaRequired, setIsTextAreaRequired] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [exportResults, setExportResults] = useState(null);
    const [buttonText, setButtonText] = useState('Copy Text to Clipboard');

    useEffect(() => {
        const { sections } = documentType;
        const section = sections.find(section => section.type === "Confirmation");
        setConfirmationSection(section);
    }, [documentType, patientId, companyId, dataHistory, docId]);

    const highlightRiskLevel = (details, riskLevel) => {
        const regex = new RegExp(`(${riskLevel})`, 'gi');
        const parts = details.split(regex);
        return parts.map((part, index) =>
            regex.test(part) ? <b key={index}><u>{part}</u></b> : part
        );
    };

    const handleConfirmation = async () => {
        if (isTextAreaRequired && !textAreaValue) {
            alert('Please fill in the required details.');
            return;
        }

        const content = {
            riskLevel: selectedRiskLevel,
            details: textAreaValue
        };

        const dataField = confirmationSection.dataField;

        try {
            await updateData(`companies/${companyId}/patients/${patientId}/documents`, docId, {
                [dataField]: content,
                status: 'exported'
            });
            const exportData = await documentExport(documentData, documentType, dataHistory);
            setExportResults(exportData);
            setConfirmationSection(null);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleRadioChange = (event) => {
        const selectedField = confirmationSection.fields.find(field => field.riskLevel === event.target.value);
        setSelectedRiskLevel(event.target.value);
        setIsTextAreaRequired(selectedField.requireTextArea);
    };

    const handleTextAreaChange = (event) => {
        setTextAreaValue(event.target.value);
    };

    const handleCopyToClipboard = () => {
        const exportTextElement = document.querySelector('.export-text');
        const htmlToCopy = exportTextElement.innerHTML;
        const blob = new Blob([htmlToCopy], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });

        navigator.clipboard.write([clipboardItem])
            .then(() => {
                setButtonText('Text Copied!');
                setTimeout(() => setButtonText('Copy Text to Clipboard'), 20000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleDownloadPDF = () => {
        if (exportResults) {
            exportPDF(exportResults);
        }
    };

    return (
        <div>
            {exportResults && documentData ? (
                <div className="standard-container">
                    <h3>Note Export</h3>
                    <div className="export-container">
                        <div className="export-text">
                            <p><strong>Patient:</strong> {personName}</p>
                            <p><strong>Date:</strong> {documentData.documentDate}</p>
                            <p><strong>Start Time:</strong> {documentData.startTime}</p>
                            <p><strong>End Time:</strong> {documentData.endTime}</p>
                            {Object.entries(exportResults.doctor).map(([key, section], index) => (
                                <div key={index}>
                                    <h4>{section.title}</h4>
                                    {section.data && <div dangerouslySetInnerHTML={{ __html: section.data }} />}
                                </div>
                            ))}
                        </div>
                        {/* <div className="export-button-container">
                            <button>Download Word Document</button>
                            <button onClick={handleDownloadPDF}>Download PDF</button>
                        </div> */}
                    </div>
                    <div className="modal-button-container">
                        <button className="button-action" onClick={handleCopyToClipboard}>{buttonText}</button>
                        <button className="button-continue" onClick={documentComplete}>Done</button>
                    </div>
                </div>
            ) : (
                confirmationSection && (
                    <div className="standard-container">
                        <h3>{confirmationSection.title}</h3>
                        {confirmationSection.details && (
                            <h4>{confirmationSection.details}</h4>
                        )}
                        {confirmationSection.fields.map((field, index) => (
                            <div key={index} className="standard-radio">
                                <input
                                    type="radio"
                                    name="riskLevel"
                                    value={field.riskLevel}
                                    onChange={handleRadioChange}
                                />
                                <span className="standard-radio-text">{highlightRiskLevel(field.details, field.riskLevel)}</span>
                            </div>
                        ))}
                        <div className="confirmation-textarea">
                            <textarea
                                className="standard-textarea"
                                placeholder={isTextAreaRequired ? "Required details..." : "Optional details..."}
                                required={isTextAreaRequired}
                                value={textAreaValue}
                                onChange={handleTextAreaChange}
                            ></textarea>
                        </div>
                        <div className="modal-button-container">
                            <button
                                className="button-continue"
                                onClick={handleConfirmation}
                                disabled={!selectedRiskLevel || (isTextAreaRequired && !textAreaValue)}
                            >
                                Export...
                            </button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default ConfirmDocument;