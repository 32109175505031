// src/Signup.js
import React, { useState } from 'react';
import { auth, db } from './utils/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import logo from './assets/juniper-logo.png';
import { companyName, firebaseErrorMessages } from './globals';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
        return passwordRegex.test(password);
    };

    const validateInviteCode = (code) => {
        return code.length >= 16;
    };

    const handleSignUp = async () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        if (!validatePhoneNumber(phoneNumber)) {
            setErrorMessage('Please enter a valid phone number.');
            return;
        }

        if (!validatePassword(password)) {
            setErrorMessage('Password must be at least 8 characters long and include uppercase and lowercase letters, and a number.');
            return;
        }

        if (!validateInviteCode(inviteCode)) {
            setErrorMessage('Invite code must be at least 16 characters long.');
            return;
        }

        try {
            const inviteDocRef = doc(db, 'companies', inviteCode);
            const inviteDoc = await getDoc(inviteDocRef);

            if (!inviteDoc.exists()) {
                throw new Error('Invalid invite code. Please check the code and try again.');
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userCollectionRef = collection(inviteDocRef, 'users');

            await setDoc(doc(userCollectionRef, user.uid), {
                email: user.email,
                phoneNumber: phoneNumber,
                createdAt: new Date(),
                companyID: inviteCode
            });

            navigate('/dashboard');
        } catch (error) {
            const friendlyMessage = firebaseErrorMessages[error.code] || error.message;
            setErrorMessage(friendlyMessage);
        }
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Logo" className="logo-login" />
            <h3 className="title">Enter your information below to begin using Juniper</h3>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="input"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="input"
            />
            <hr></hr>
            <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Phone Number"
                className="input"
            />
            <input
                type="text"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                placeholder="Enter your Invite Code"
                className="input"
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button onClick={handleSignUp} className="center-button primary">Sign Up</button>
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} {companyName}. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Signup;