// Simple Data Table editor for basic data objects

import React, { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { updateData } from '../utils/firestoreService';

const capitalizeAndFormat = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const ResizableTextarea = ({ value, handleChange, handleBlur, fieldKey, history }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 4}px`;
        }
    }, [value, history]);

    const isEmpty = value === undefined || value === null || value === '';

    return (
        <textarea
            ref={textareaRef}
            value={isEmpty ? history : value}
            className={`standard-textarea column-100 ${isEmpty ? 'column-history' : ''}`}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            onBlur={() => handleBlur()}
            rows={1}
        />
    );
};

const renderValue = (value, handleChange, handleBlur, fieldKey, history) => {

    let historyDataItem = '';
    let historyData = []; //allow for peeking at all history in the future

    if (history && fieldKey && Array.isArray(history[fieldKey])) {
        const sortedData = history[fieldKey].sort((a, b) => {
            if (a.date.seconds !== b.date.seconds) {
                return b.date.seconds - a.date.seconds;
            }
            return b.date.nanoseconds - a.date.nanoseconds;
        });

        if (sortedData.length > 0) {
            historyDataItem = sortedData[0].value;
        }

        historyData = sortedData.map(item => ({
            value: item.value,
            date: item.date
        }));

    }

    if (typeof value === 'object' && value !== null) {
        return (
            <table className="standard-table" style={{ marginLeft: '20px' }}>
                <tbody>
                    {Object.keys(value).sort().map((subKey) => {

                        return (
                            <tr key={subKey}>
                                <td className='column-10 column-right'>{capitalizeAndFormat(subKey)}</td>
                                <td className={`column-100`}>
                                    {renderValue(
                                        value[subKey],
                                        handleChange,
                                        handleBlur,
                                        `${fieldKey}.${subKey}`,
                                        history
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    return (
        <ResizableTextarea
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            fieldKey={fieldKey}
            history={historyDataItem}
        />
    );
};

const processHistory = (dataHistory, originalFieldKey) => {

    if (!dataHistory) return;

    const combinedData = {}; // simpler result, not returned
    const fullHistoryData = {};

    for (let i = dataHistory.length - 1; i >= 0; i--) {
        const record = dataHistory[i];
        const fieldData = record[originalFieldKey];
        const date = record.modifiedAt;
        for (const key in fieldData) {
            if (fieldData[key] !== "") {
                combinedData[key] = fieldData[key];
                if (!fullHistoryData[key]) {
                    fullHistoryData[key] = [];
                }
                fullHistoryData[key].push({ value: fieldData[key], date: date });
            }
        }
    }

    return fullHistoryData;
};

const EditableDataSection = ({ title, data, dataHistory, patientId, companyId, docId, originalFieldKey, alphabetize = false, defaultOpen = true, fields = [], columnWidth = 20 }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [editedData, setEditedData] = useState(data || {});

    const combinedHistory = processHistory(dataHistory, originalFieldKey);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (fieldKey, value) => {
        const keys = fieldKey.split('.');
        const newData = { ...editedData };
        let temp = newData;
        keys.forEach((k, index) => {
            if (index === keys.length - 1) {
                temp[k] = value;
            } else {
                if (!temp[k]) {
                    temp[k] = {};
                }
                temp = temp[k];
            }
        });
        setEditedData(newData);
    };

    const handleBlur = async () => {
        try {
            await updateData(`companies/${companyId}/patients/${patientId}/documents`, docId, { [originalFieldKey]: editedData });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const sortedKeys = Object.keys(editedData).sort((a, b) => {
        if (alphabetize) {
            return a.localeCompare(b);
        }
        return 0;
    });

    const displayKeys = fields.length > 0 ? fields : sortedKeys;

    return (
        <div className='standard-container'>
            <div className="edit-header-container">
                <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                    <h3 className="edit-header">
                        <span>
                            {isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}{title}
                        </span>
                    </h3>
                </div>
                <div className="edit-header-right">
                </div>
            </div>
            {isOpen && (
                <table className='standard-table'>
                    <tbody>
                        {displayKeys.map((key) => (
                            <tr key={key}>
                                <td className={`column-first column-gray column-${columnWidth}`}>{capitalizeAndFormat(key)}:</td>
                                <td className="column-100">{renderValue(editedData[key], handleChange, handleBlur, key, combinedHistory)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default EditableDataSection;