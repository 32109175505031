import React, { useEffect, useState } from 'react';
import { addData, getData, deleteData } from './utils/firestoreService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './utils/authContext';
import MenuComponent from './utils/menuComponent';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon  from '@mui/icons-material/DeleteOutline';
import MicIcon from '@mui/icons-material/Mic';

const fetchCompanyIDFromSession = async () => {
    try {
        const response = await fetch('/getCompanyID', {
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            return data.companyID;
        } else {
            console.error('Failed to fetch companyID');
            return null;
        }
    } catch (error) {
        console.error('Error fetching companyID:', error);
        return null;
    }
};

const Dashboard = () => {
    const [items, setItems] = useState([]);
    const [patients, setPatients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { currentUser } = useAuth();
    const userID = currentUser?.uid;
    const navigate = useNavigate();
    const [newPatientFirstName, setNewPatientFirstName] = useState('');
    const [newPatientLastName, setNewPatientLastName] = useState('');
    const [newPatientDOB, setNewPatientDOB] = useState('');
    const [newPatientGender, setNewPatientGender] = useState('');
    const [newPatientPronoun, setNewPatientPronoun] = useState('');
    const [dobError, setDobError] = useState('');
    const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);

    const datePattern = /^(0[1-9]|1[0-2])[-\/](0[1-9]|[12][0-9]|3[01])[-\/](\d{4})$/;

    const fetchItems = async () => {
        try {
            const companyID = await fetchCompanyIDFromSession();
            if (!companyID || !userID) return;

            const data = await getData(`companies/${companyID}/users/${userID}/tasks`);
            const sortedData = data
                .map(item => ({ ...item, completed: item.completed || false }))
                .sort((a, b) => a.order - b.order);
            setItems(sortedData);
        } catch (error) {
            console.error("Error fetching items:", error);
        }
    };

    const fetchPatients = async () => {
        try {
            const companyID = await fetchCompanyIDFromSession();
            if (!companyID) return;

            const patientsList = await getData(`companies/${companyID}/patients`);
            const pinnedPatients = patientsList.filter(patient => patient.pin === true);
            setPatients(pinnedPatients);
        } catch (error) {
            console.error("Error fetching patients:", error);
        }
    };

    useEffect(() => {
        fetchItems();
        fetchPatients();
    }, [userID]);

    const handleHistoryClick = (patient) => {
        navigate('/Patient', { state: { patientId: patient.id } });
    };

    const handleRecordClick = (patient) => {
        navigate('/NewSession', { state: { patientId: patient.id } });
    };


    const handleAddPatient = async () => {
        if (currentUser) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                if (!newPatientFirstName.trim()) {
                    console.error('First name cannot be empty');
                    return;
                }

                if (!datePattern.test(newPatientDOB)) {
                    console.error('Invalid Date of Birth format');
                    return;
                }

                const patientData = {
                    firstName: newPatientFirstName,
                    lastName: newPatientLastName,
                    dateOfBirth: newPatientDOB,
                    gender: newPatientGender,
                    pronoun: newPatientPronoun,
                    createdAt: new Date(),
                    pin: true,
                    memberOf: [companyID]
                };

                //const newPatientId = await addData(`/patients`, patientData); // future version which store patients in a separate collection

                const newPatientId = await addData(`companies/${companyID}/patients`, patientData);

                setPatients(prevPatients => [
                    ...prevPatients,
                    { id: newPatientId, ...patientData }
                ]);

                setNewPatientFirstName('');
                setNewPatientLastName('');
                setNewPatientDOB('');
                setNewPatientGender('');
                setNewPatientPronoun('');
                setIsAddPatientOpen(false);
            }
        }
    };

    const handleDOBBlur = () => {
        if (!datePattern.test(newPatientDOB)) {
            setDobError('Invalid Date of Birth format');
        } else {
            setDobError('');
        }
    };

    const isFormValid = () => {
        return newPatientFirstName.trim() && newPatientLastName.trim() && datePattern.test(newPatientDOB) && newPatientGender && newPatientPronoun;
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDeletePatient = async (patientId) => {

        const confirmDelete = window.confirm('Are you sure you want to delete this patient? This is permanent and there is no way to recover the patient data in this PreMVP version.');
        if (!confirmDelete) return;

        if (currentUser) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                await deleteData(`companies/${companyID}/patients`, patientId);

                setPatients((prevPatients) =>
                    prevPatients.filter((patient) => patient.id !== patientId)
                );
            }
        }
    };

    const filteredPatients = patients.filter(patient =>
        `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='page-container'>
            <div className='header-container'>
                <div className='page-title'>Welcome to Juniper</div>
                <div className='header-search'>
                    <div className='header-search-wrapper'>
                        <SearchIcon className='header-search-icon' />
                        <input
                            type="text"
                            placeholder="Search Patients"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className='header-search-input'
                        />
                    </div>
                </div>
                <div title="Add Patient" className="header-button" onClick={() => setIsAddPatientOpen(true)}>
                    <PersonAddIcon className="header-icon" /> Add Patient
                </div>
                {isAddPatientOpen && (
                    <div className="modal" onClick={() => setIsAddPatientOpen(false)}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className='modal-title'>Add New Patient</div>
                            <div className='modal-form'>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={newPatientFirstName}
                                    onChange={(e) => setNewPatientFirstName(e.target.value)}
                                    className='modal-input'
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={newPatientLastName}
                                    onChange={(e) => setNewPatientLastName(e.target.value)}
                                    className='modal-input'
                                />
                                <input
                                    type="text"
                                    placeholder="Date of Birth (MM/DD/YYYY)"
                                    value={newPatientDOB}
                                    onChange={(e) => setNewPatientDOB(e.target.value)}
                                    onBlur={handleDOBBlur}
                                    className='modal-input'
                                />
                                {dobError && <div className="modal-error-message">{dobError}</div>}
                                <div className='modal-radio'>Gender Identity:
                                    <label>
                                        <input
                                            type="radio"
                                            value="Male"
                                            checked={newPatientGender === 'Male'}
                                            onChange={(e) => setNewPatientGender(e.target.value)}
                                        />
                                        Male
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Female"
                                            checked={newPatientGender === 'Female'}
                                            onChange={(e) => setNewPatientGender(e.target.value)}
                                        />
                                        Female
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Non-binary"
                                            checked={newPatientGender === 'Non-binary'}
                                            onChange={(e) => setNewPatientGender(e.target.value)}
                                        />
                                        Non-binary
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Prefer not to say"
                                            checked={newPatientGender === 'Prefer not to say'}
                                            onChange={(e) => setNewPatientGender(e.target.value)}
                                        />
                                        Prefer not to say
                                    </label>
                                </div>
                                <div className='modal-dropdown'>
                                    <label>Pronouns:
                                        <select
                                            value={newPatientPronoun}
                                            onChange={(e) => setNewPatientPronoun(e.target.value)}
                                            className='modal-select'
                                        >
                                            <option value="">Select Pronouns</option>
                                            <option value="he/him">He/Him</option>
                                            <option value="she/her">She/Her</option>
                                            <option value="they/them">They/Them</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="modal-button-container">
                                    <button className="button-cancel" onClick={() => setIsAddPatientOpen(false)}>
                                        Cancel
                                    </button>
                                    <button
                                        className="button-continue"
                                        onClick={handleAddPatient}
                                        disabled={!isFormValid()}
                                    >
                                        Add Patient
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <div className='standard-container'>
                <h3>Patients</h3>
                <table className='standard-table thinner-rules'>
                    <thead>
                        <tr>
                            <th></th>
                            <th className='column-5'></th>
                            <th className='column-5'></th>
                            <th className='column-5'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPatients.map(patient => (
                            <tr  key={patient.id}>
                                <td onClick={() => handleHistoryClick(patient)} style={{ cursor: 'pointer' }}>
                                    <span className="table-primary-text">
                                        {patient.firstName} {patient.lastName.charAt(0)}.
                                    </span>
                                    {patient.dateOfBirth && (
                                        <span className="table-secondary-text"> DOB: {patient.dateOfBirth}</span>
                                    )}
                                    {patient.pronoun && (
                                        <span className="table-secondary-text"> Pronouns: {patient.pronoun}</span>
                                    )}
                                </td>
                                <td>
                                <MicIcon className='header-icon' onClick={() => handleRecordClick(patient)} title="Record Session"></MicIcon>
                                </td>
                                <td>
                                <DeleteIcon className='header-icon' onClick={() => handleDeletePatient(patient.id)} title="Delete Patient?"></DeleteIcon>
                                </td>
                                <td> <MenuComponent patientId={patient.id} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;