import React from 'react';

const Company = () => {
    return (
        <div className='page-container'>
            <div className='page-title'>Settings Page</div>
            <p>Welcome to the page.</p>
        </div>
    );
};

export default Company;
