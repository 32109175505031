import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './utils/authContext';
import logo from './assets/juniper-logo.png';
import { companyName, versionNum } from './globals';

import './css/Sidebar.css';

const Sidebar = () => {
    const { currentUser, signOut } = useAuth();
    const [companyID, setCompanyID] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchCompanyID = async () => {
            try {
                const response = await fetch('/getCompanyID');
                if (response.ok) {
                    const data = await response.json();
                    setCompanyID(data.companyID);
                } else {
                    console.error('Failed to fetch companyID');
                }
            } catch (error) {
                console.error('Error fetching companyID:', error);
            }
        };

        fetchCompanyID();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            alert(error.message);
        }
    };

    const isActive = (path) => location.pathname === path;




    return (
        <div className="sidebar">

            <Link to="/dashboard">
                <img src={logo} alt="Logo" className="logo-sidebar" />
            </Link>

            <div className="user-info">Welcome:
                <p>{currentUser ? currentUser.email : 'Guest'}</p>
                {/* {companyID && <p>Company ID: {companyID}</p>} */}
            </div>
            <ul>
                <li className={isActive('/dashboard') ? 'active' : ''}><Link to="/dashboard">Today</Link></li>
                {/* <li className={isActive('/patients') ? 'active' : ''}><Link to="/patients">Patients</Link></li> */}
                {/* <li className={isActive('/settings') ? 'active' : ''}><Link to="/settings">Settings</Link></li> */}

                {/* <li className={isActive('/newSession') ? 'active' : ''}><Link to="/newSession">New Session</Link></li> */}
                {/* <li className={isActive('/session') ? 'active' : ''}><Link to="/session">Sessions</Link></li>
                <li className={isActive('/document') ? 'active' : ''}><Link to="/document">Documents</Link></li>
                <li className={isActive('/schedule') ? 'active' : ''}><Link to="/schedule">Schedule</Link></li>
                <li className={isActive('/company') ? 'active' : ''}><Link to="/company">Company</Link></li> */}

            </ul>
            <button className="logout-button" onClick={handleLogout}>Sign Out</button>
            <div className="sidebar-footer">
                &copy; {new Date().getFullYear()} {companyName} v{versionNum}.<br />All rights reserved.
            </div>
        </div>
    );
};

export default Sidebar;
