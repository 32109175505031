import React, { useContext, createContext, useEffect, useState } from 'react';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signOut: () => signOut(auth)
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
