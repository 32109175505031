import '../css/Progress.css';

class ProgressBar {
    constructor(containerId, eta = 0, isProcessing = true, isStreaming = true) {
        this.container = document.getElementById(containerId);
        this.title = '';
        this.details = '';
        this.percentage = 0;
        this.eta = eta;
        this.time = 0; // Initialize elapsed time
        this.isProcessing = isProcessing;
        this.init();
    }

    init() {
        if (this.isProcessing) {
            this.container.innerHTML = `
                <div class="progress-bar">
                    <div class="progress-bar-percentage">
                        <div class="progress-bar-fill" style="width: 1%;"></div>
                    </div>
                    <div class="progress-bar-info">
                        <span class="progress-bar-title"></span><span class="progress-bar-details"></span>
                        <div class="progress-bar-time"></div>
                    </div>
                </div>
            `;
            this.titleElement = this.container.querySelector('.progress-bar-title');
            this.detailsElement = this.container.querySelector('.progress-bar-details');
            this.fillElement = this.container.querySelector('.progress-bar-fill');
            this.timeElement = this.container.querySelector('.progress-bar-time');

            // Set initial ETA and elapsed time
            this.timeElement.textContent = `Elapsed Time: ${this.formatTime(this.time)} | ETA: ${this.formatEta(this.eta)}`;
        } else {
            this.container.innerHTML = ''; // Clear the container if not processing
        }
    }

    update(data) {
        if (data.title !== undefined) this.title = data.title;
        if (data.details !== undefined) this.details = data.details;
        if (data.percentage !== undefined) this.percentage = data.percentage;
        if (data.time !== undefined && data.time > 0) this.time = data.time;
    
        if (this.isProcessing) { //add a unique version for isStreaming
            this.titleElement.textContent = this.title ? this.title + ': ' : '';
            this.detailsElement.textContent = this.details;
            this.fillElement.style.width = `${this.percentage}%`;
            this.timeElement.textContent = `Elapsed Time: ${this.formatTime(this.time)} | Estimated Time: ${this.formatEta(this.eta)}`;
        }
    }

    formatEta(eta) {
        const minutes = Math.floor(eta / 60).toString().padStart(2, '0');
        const seconds = (eta % 60).toString().padStart(2, '0');
        return `${minutes}m ${seconds}s`;
    }

    formatTime(time) {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0');
        const seconds = (time % 60).toString().padStart(2, '0');
        return `${minutes}m ${seconds}s`;
    }
}

export const parseProgressFromMessage = (message, previousProgress) => {
    try {
        const data = JSON.parse(message);
        if (data.hasOwnProperty('percentage')) {
            let percentage;
            if (typeof data.percentage === 'number') {
                percentage = data.percentage;
            } else if (typeof data.percentage === 'string' && data.percentage.startsWith('+')) {
                const increment = parseFloat(data.percentage);
                if (!isNaN(increment)) {
                    percentage = previousProgress + increment;
                } else {
                    percentage = previousProgress;
                }
            } else {
                percentage = previousProgress;
            }
            return {
                title: data.title || '',
                details: data.details || '',
                percentage: percentage,
                eta: data.eta || 0,
                time: data.time || 0 // Include elapsed time if provided
            };
        }
        return null;
    } catch (error) {
        return null;
    }
};

export default ProgressBar;