import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAVJehFCZi8HFjsdo2F1EZQeIJgrOwlHqA",
    authDomain: "juniperai.firebaseapp.com",
    projectId: "juniperai",
    storageBucket: "juniperai.appspot.com",
    messagingSenderId: "924303952632",
    appId: "1:924303952632:web:7e0768623de254f741490d",
    measurementId: "G-HWJHCTZBDH",
    databaseURL: "https://juniperai.firebaseio.com"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
