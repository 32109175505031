import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateData } from '../utils/firestoreService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import debounce from 'lodash/debounce';

const EditableTextSection = ({ documentData, copyToClipboard, ContentCopyIcon, patientId, companyId, docId, dataField, toolbarType = 'full', defaultOpen = true, title, defaultData = '', message = '' }) => {

    const initialContent = typeof documentData[dataField] === 'string'
        ? documentData[dataField]
        : JSON.stringify(documentData[dataField], null, 2) || defaultData;

    const [editorContent, setEditorContent] = useState(initialContent);
    const [isSaving, setIsSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = async (content) => {
        setIsSaving(true);
        try {
            await updateData(`companies/${companyId}/patients/${patientId}/documents`, docId, { [dataField]: content });
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setIsSaving(false);
            setIsEditing(false);
        }
    };

    const debouncedSave = debounce((content) => handleSave(content), 3000);

    const handleEditorChange = (content) => {
        setEditorContent(content);
        setIsEditing(true);
        debouncedSave(content);
    };

    const handleBlur = () => {
        handleSave(editorContent);
    };

    useEffect(() => {
        const autoSaveInterval = setInterval(() => {
            if (isEditing) {
                handleSave(editorContent);
            }
        }, 15000);

        return () => clearInterval(autoSaveInterval);
    }, [editorContent, isEditing]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const getToolbarOptions = (type) => {
        switch (type) {
            case 'minimal':
                return [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ];
            case 'none':
                return false;
            case 'full':
            default:
                return [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }]
                ];
        }
    };

    /*
                previous full editor:

                    [{ 'header': [3, 4, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': [] }, { 'background': [] }],
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'align': [] }],
                    ['link']
    */

    const toolbarOptions = getToolbarOptions(toolbarType);

    return (
        <div className='standard-container'>
            <div className="edit-header-container">
                <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                    <h3 className="edit-header">
                        <span>
                            {isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}
                        </span>
                        {title}
                        {isSaving && <span className="status-text">Saving...</span>}
                    </h3>
                </div>
                <div className="edit-header-right">
                    <span
                        title='Copy Note Text'
                        onClick={() => copyToClipboard(editorContent)}
                        className='copy-icon'
                    >
                        {isOpen ? <ContentCopyIcon style={{ fontSize: '16px' }} /> : ''}
                    </span>
                </div>
            </div>
            {isOpen && (
                <div className='text-editor-container'>
                    <div className='text-editor-box' style={{ resize: 'vertical', overflow: 'auto' }}>
                        <ReactQuill
                            theme="snow"
                            value={editorContent}
                            onChange={handleEditorChange}
                            onBlur={handleBlur}
                            modules={{ toolbar: toolbarOptions }}
                            style={{ height: '96%' }}
                        />

                    </div>
                    <div className="editor-message">
                        {message}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditableTextSection;