import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getData, deleteData, getDocument } from './utils/firestoreService';
import MenuComponent from './utils/menuComponent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MicIcon from '@mui/icons-material/Mic';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

let patientData = null; // Define patientData as a global variable

const Patient = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId } = location.state || {};
    const [personName, setPersonName] = useState('');
    const [companyID, setCompanyID] = useState(null);
    const [patientDocuments, setPatientDocuments] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const hasFetchedDataRef = useRef(false);
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    // Paging state
    const [currentPage, setCurrentPage] = useState(1);
    const documentsPerPage = 10;

    const handleBack = () => {
        navigate(-1);
    };

    const fetchCompanyIDFromSession = async () => {
        try {
            const response = await fetch('/getCompanyID', {  // does company id twice
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                return data.companyID;
            } else {
                console.error('Failed to fetch companyID');
                return null;
            }
        } catch (error) {
            console.error('Error fetching companyID:', error);
            return null;
        }
    };

    useEffect(() => {
        if (hasFetchedDataRef.current) return;

        if (!patientId) {
            navigate('/patient', { replace: true });
            return;
        }

        const fetchCompanyIDAndPatientData = async () => {
            try {
                hasFetchedDataRef.current = true;
                const response = await fetch('/getCompanyID'); // does company id twice
                if (response.ok) {
                    const data = await response.json();
                    setCompanyID(data.companyID);

                    patientData = await getDocument(`companies/${data.companyID}/patients`, patientId);

                    if (patientData) {
                        const formattedPersonName = `${patientData.firstName} ${patientData.lastName.charAt(0)}.`;
                        setPersonName(formattedPersonName);

                        let documents = await getData(`companies/${data.companyID}/patients/${patientId}/documents`);

                        documents = documents.sort((a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds);

                        setPatientDocuments(documents);

                    } else {
                        console.error('No such patient!');
                    }
                } else {
                    console.error('Failed to fetch companyID');
                }
            } catch (error) {
                console.error('Error fetching companyID:', error);
            }
        };

        fetchCompanyIDAndPatientData();

    }, [patientId, navigate]);

    const handleDeleteDocument = async (docId) => {
        const companyID = await fetchCompanyIDFromSession();

        if (companyID) {
            await deleteData(`companies/${companyID}/patients/${patientId}/documents`, docId);
            setPatientDocuments(prevDocs => prevDocs.filter(doc => doc.id !== docId));
        }
    };

    const handleDocumentClick = (docId) => {
        navigate('/document', { state: { patientId, docId } });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter documents based on search query
    const filteredDocuments = patientDocuments.filter(doc =>
        doc.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Paging functions
    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const indexOfLastDocument = currentPage * documentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
    const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
    const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

    return (
        <div className='page-container'>
            <div className='header-container'>
                <button onClick={handleBack}>&larr;</button>
                {personName && (
                    <div className='page-title'>
                        Patient Records for {personName}
                    </div>
                )}
                {personName && <MenuComponent patientId={patientId} />}
            </div>
            <div className='patient-content-container'>

                    <div className="documents-list-container">
                        <div className='header-container'>
                            <div className='section-title'>
                                Recent Documents
                            </div>
                            <div className='header-search'>
                                <div className='header-search-wrapper'>
                                    <SearchIcon className='header-search-icon' />
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className='header-search-input'
                                    />
                                </div>
                            </div>
                        </div>
                        <table className="standard-table">
                            <thead>
                                <tr>
                                    <th className='column-5'></th>
                                    <th className='column-60'></th>
                                    <th className='column-5'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentDocuments.length === 0 ? (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: 'center' }}>No documents found...</td>
                                    </tr>
                                ) : (
                                    currentDocuments.map((doc, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button onClick={() => handleDocumentClick(doc.id)} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
                                                    {doc.typeId === 1 || doc.typeId === 2 ? (
                                                        <MicIcon className="table-icon" />
                                                    ) : (
                                                        <AssignmentIcon className="table-icon" />
                                                    )}
                                                </button>
                                            </td>
                                            <td>
                                                <h4>
                                                    <strong onClick={() => handleDocumentClick(doc.id)} style={{ cursor: 'pointer' }}>
                                                        {doc.title}
                                                    </strong>
                                                    <span className='summary-small' style={{ marginLeft: '10px' }}>
                                                        {doc.documentDate}
                                                    </span>
                                                </h4>
                                                <span className='summary-small'>
                                                    {doc.short_summary ? (doc.short_summary.length > 80 ? doc.short_summary.substring(0, 80) + '...' : doc.short_summary) : ''}
                                                </span>
                                            </td>
                                            <td>
                                                <DeleteOutlineIcon className='table-icon-lighter' onClick={() => setDeleteConfirmOpen(true)} ></DeleteOutlineIcon>
                                            </td>
                                            {isDeleteConfirmOpen && (
                                                <div className="modal" onClick={() => setDeleteConfirmOpen(false)}>
                                                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                                        <div className='modal-title'>Are you sure you want to delete this document? In this preMVP version there's no undo!</div>
                                                        <div className="modal-button-container">
                                                            <button className="button-cancel" onClick={() => setDeleteConfirmOpen(false)}>
                                                                Cancel
                                                            </button>
                                                            <button className="button-delete" onClick={() => {
                                                                handleDeleteDocument(doc.id);
                                                                setDeleteConfirmOpen(false);
                                                            }}>Yes, Delete</button>
                                                        </div>  
                                                    </div>
                                                </div>
                                            )}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-controls">
                            <NavigateBeforeIcon onClick={handlePreviousPage} style={{ cursor: currentPage === 1 ? 'default' : 'pointer', opacity: currentPage === 1 ? 0.5 : 1 }} />
                            <span>Page {currentPage} of {totalPages}</span>
                            <NavigateNextIcon onClick={handleNextPage} style={{ cursor: indexOfLastDocument >= filteredDocuments.length ? 'default' : 'pointer', opacity: indexOfLastDocument >= filteredDocuments.length ? 0.5 : 1 }} />
                        </div>
                    </div>
               
                <div className="patient-details-container">
                    <div className='header-container'>
                        <div className='section-title'>
                            Patient Details
                        </div>
                    </div>
                    {patientData && (
                        <div className='patient-details'>
                            <p><strong>Full Name:</strong> {patientData.firstName} {patientData.lastName}</p>
                            <p><strong>Date of Birth:</strong> {patientData.dateOfBirth}</p>
                            <p><strong>Gender:</strong> {patientData.gender}</p>
                            <br />
                            <p><strong>Goals:</strong></p>
                            <p>This section will display AI generated Goals</p>
                            <br />
                            <p><strong>History Summary:</strong></p>
                            <p>This section will display a summary of summaries</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Patient;