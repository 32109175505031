// Excel style wide table editor for complex objects, with option to match the Data Section editor

import React, { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { updateData } from '../utils/firestoreService';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const EditableTableSection = ({ title, data, dataHistory, subData, patientId, companyId, docId, defaultOpen = true, primaryField = '', displayFields = [], useFields = [], columnWidths = [], editorStyle, showHeader, displayHistory }) => {

    useEffect(() => {
        if (displayHistory && dataHistory) {
            const filteredHistory = dataHistory.map(record => record[subData]);
            //console.log(filteredHistory)
        }
    }, [displayHistory, dataHistory]);

    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [editedData, setEditedData] = useState(subData ? data[subData] || [] : data || []);
    const [hiddenRowsVisible, setHiddenRowsVisible] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (key, value) => {
        const keys = key.split('.').filter(Boolean);
        const newData = [...editedData];
        let temp = newData;
        keys.forEach((k, index) => {
            if (index === keys.length - 1) {
                temp[k] = value;
            } else {
                if (!temp[k]) {
                    temp[k] = {};
                }
                temp = temp[k];
            }
        });
        setEditedData(newData);
    };

    const handleBlur = async (index, subKey, value) => {
        try {
            const updatedData = editedData.map((item, i) => (
                i === index ? { ...item, [subKey]: value } : item
            ));
            const updatedMainData = subData ? { ...data, [subData]: updatedData } : { data: updatedData };
            await updateData(`companies/${companyId}/patients/${patientId}/documents`, docId, updatedMainData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleAddRow = () => {
        const newRow = orderedKeys.reduce((acc, key) => ({ ...acc, [key]: '' }), {});
        setEditedData([...editedData, newRow]);
    };

    const handleHideRow = async (index) => {
        try {
            const updatedData = editedData.map((item, i) => (
                i === index ? { ...item, hide: !item.hide } : item
            ));
            const updatedMainData = subData ? { ...data, [subData]: updatedData } : { data: updatedData };
            await updateData(`companies/${companyId}/patients/${patientId}/documents`, docId, updatedMainData);
            setEditedData(updatedData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const getOrderedKeys = () => {
        const keys = Object.keys(editedData[0] || {});
        return useFields.filter(field => keys.includes(field));
    };

    const orderedKeys = getOrderedKeys();
    const keysToRender = editorStyle === 'dataSection' ? orderedKeys.slice(0, 4) : orderedKeys;

    return (
        <div className='standard-container'>
            <div className="edit-header-container">
                <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                    <h3 className="edit-header">
                        <span>
                            {isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}
                        </span>
                        {title}
                    </h3>
                </div>
                <div className="edit-header-right">
                    {isOpen ? (
                        <Tooltip title={hiddenRowsVisible ? "Hide Hidden Rows" : "Show Hidden Rows"}>
                            {hiddenRowsVisible ? (
                                <VisibilityOffIcon className="header-icon" onClick={() => setHiddenRowsVisible(false)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                            ) : (
                                <VisibilityIcon className="header-icon" onClick={() => setHiddenRowsVisible(true)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                            )}
                        </Tooltip>
                    ) : ''}
                    {isOpen ? (
                        <Tooltip title="Add Row">
                            <AddIcon className="header-icon" onClick={handleAddRow} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                        </Tooltip>
                    ) : ''}
                </div>
            </div>
            {isOpen && (
                <div className='table-container'>
                    <table className={`standard-table ${editorStyle !== 'dataSection' ? 'wide' : ''}`}>
                        <colgroup>
                            {keysToRender.map((key, index) => (
                                <col key={key} style={{ width: `${columnWidths[index]}%` }} />
                            ))}
                            <col style={{ width: '2.6%' }} />
                        </colgroup>
                        {showHeader !== false && (
                            <thead>
                                <tr>
                                    {keysToRender.map((key, index) => (
                                        <th key={key} className={`column-${columnWidths[index]}`} data-original-key={key}>{displayFields[index]}</th>
                                    ))}
                                    <th style={{ width: '2.6%' }}></th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {editedData.map((item, index) => (
                                (!item.hide || hiddenRowsVisible) && (
                                    <React.Fragment key={index}>
                                        {renderValue(item, handleChange, handleBlur, index, orderedKeys, primaryField, columnWidths, editorStyle, handleHideRow)}
                                    </React.Fragment>
                                )
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

const ResizableTextarea = ({ value, handleChange, handleBlur, fieldKey, history, className }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 4}px`;
        }
    }, [value, history]);

    const isEmpty = value === undefined || value === null || value === '';

    return (
        <textarea
            ref={textareaRef}
            value={isEmpty ? history : value}
            className={`standard-textarea${isEmpty ? ' column-history' : ''} ${className}`}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            onBlur={() => handleBlur()}
            rows={1}
        />
    );
};

const renderValue = (item, handleChange, handleBlur, index, orderedKeys, primaryField, columnWidths, editorStyle, handleHideRow) => {
    const keysToRender = editorStyle === 'dataSection' ? orderedKeys.slice(0, 4) : orderedKeys;

    return (
        <tr key={index}>
            {keysToRender.map((subKey, subIndex) => (
                <React.Fragment key={subKey}>
                    <td className={`column-${columnWidths[subIndex]}`}>
                        {subKey === primaryField ? (
                            <span>{item[subKey]}</span>
                        ) : (
                            <ResizableTextarea
                                value={item[subKey] || ''}
                                handleChange={(key, value) => handleChange(`${index}.${subKey}`, value)}
                                handleBlur={() => handleBlur(index, subKey, item[subKey] || '')}
                                fieldKey={`${index}.${subKey}`}
                                history={item[subKey] || ''}
                                className={subIndex === 0 ? 'column-first' : ''}
                            />
                        )}
                    </td>
                </React.Fragment>
            ))}
            <td>
                <Tooltip title="Hide Row">
                    <RemoveIcon className='table-icon' style={{ cursor: 'pointer' }} onClick={() => handleHideRow(index)} />
                </Tooltip>
            </td>
        </tr>
    );
};

export default EditableTableSection;