import { getDocument, getDocumentsByType } from './firestoreService';

const fetchCompanyIDFromSession = async () => {
    try {
        const response = await fetch('/getCompanyID', {
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            return data.companyID;
        } else {
            console.error('Failed to fetch companyID');
            return null;
        }
    } catch (error) {
        console.error('Error fetching companyID:', error);
        return null;
    }
};

export const fetchDocumentData = async (patientId, docId, setCompanyID, setPersonName, setDocumentData, setTitle, setDocumentHistory) => {
    try {
        const companyID = await fetchCompanyIDFromSession();
        if (companyID) {
            setCompanyID(companyID);

            const patientData = await getDocument(`companies/${companyID}/patients`, patientId);
            if (patientData) {
                const formattedPersonName = `${patientData.firstName} ${patientData.lastName}`; //.charAt(0).
                setPersonName(formattedPersonName);
                const documentData = await getDocument(`companies/${companyID}/patients/${patientId}/documents`, docId);
                setDocumentData(documentData);
                setTitle(documentData.title);

                // build history object of previous records from audio sessions
                if (documentData.typeId === 1 || documentData.typeId === 2) {
                    const previousDocumentsType1 = await getDocumentsByType(`companies/${companyID}/patients/${patientId}/documents`, 1);
                    const previousDocumentsType2 = await getDocumentsByType(`companies/${companyID}/patients/${patientId}/documents`, 2);
                    const previousDocuments = [...previousDocumentsType1, ...previousDocumentsType2];
                    const filteredDocuments = previousDocuments.filter(doc => doc.id !== docId && doc.modifiedAt <= documentData.modifiedAt);
                    if (filteredDocuments.length > 0) {
                        setDocumentHistory(filteredDocuments);
                    }
                }
            } else {
                console.error('No such patient!');
            }
        } else {
            console.error('Failed to fetch companyID');
        }
    } catch (error) {
        console.error('Error fetching document data:', error);
    }
};