const capitalizeAndFormat = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const processHistory = (dataHistory, originalFieldKey) => {
    if (!dataHistory) return;

    const fullHistoryData = {};

    for (let i = dataHistory.length - 1; i >= 0; i--) {
        const record = dataHistory[i];
        const fieldData = record[originalFieldKey];
        const date = record.modifiedAt;
        for (const key in fieldData) {
            if (fieldData[key] !== "") {
                if (!fullHistoryData[key]) {
                    fullHistoryData[key] = [];
                }
                fullHistoryData[key].push({ value: fieldData[key], date: date });
            }
        }
    }

    return fullHistoryData;
};

const generateSectionExport = (section, documentData, dataHistory) => {
    switch (section.type) {
        case 'EditableTextSection':
            return {
                [section.dataField]: {
                    data: documentData[section.dataField],
                    title: section.title
                }
            };
        case 'EditableDataSection':
            const dataItems = documentData[section.data];
            const historyData = processHistory(dataHistory, section.data);
            let formattedData = '<ul>';

            section.fields.forEach(field => {
                const value = dataItems && dataItems[field]
                    ? dataItems[field]
                    : (historyData && historyData[field] && historyData[field][0].value)
                        ? historyData[field][0].value
                        : section.defaultExport;
                formattedData += `<li><strong>${capitalizeAndFormat(field)}:</strong> ${value}</li>`;
            });

            formattedData += '</ul>';
            return {
                [section.data]: {
                    data: formattedData,
                    title: section.title
                }
            };
        case 'EditableTableSection':
            const tableData = documentData[section.subData];
            let formattedTableData = '<ul>';

            tableData.forEach(item => {
                formattedTableData += '<li>';
                section.useFields.forEach((field, index) => {
                    if (item[field]) {
                        if (index === 0) {
                            formattedTableData += `<strong>${capitalizeAndFormat(item[field])}:</strong> `;
                        } else {
                            formattedTableData += `${item[field]} `;
                        }
                    }
                });
                formattedTableData += '</li>';
            });

            formattedTableData += '</ul>';
            return {
                [section.subData]: {
                    data: formattedTableData,
                    title: section.title
                }
            };
        default:
            return null;
    }
};

export const documentExport = (documentData, documentType, dataHistory) => {
    const exports = {};

    documentType.sections.forEach(section => {
        if (section.exports && section.exports.length > 0) {
            section.exports.forEach(exportType => {
                if (!exports[exportType]) {
                    exports[exportType] = {};
                }
                const sectionExport = generateSectionExport(section, documentData, dataHistory);
                if (sectionExport) {
                    Object.assign(exports[exportType], sectionExport);
                }
            });
        }
    });
    return exports;
};