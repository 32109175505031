import { getDocument, getData } from './firestoreService';

export const fetchPatientData = async (patientId, setCompanyID, setPatientData, setPatientDocuments) => {
    try {

        const response = await fetch('/getCompanyID', {
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            setCompanyID(data.companyID);

            const patientData = await getDocument(`companies/${data.companyID}/patients`, patientId);

            if (patientData) {
                if (setPatientData) {
                    setPatientData(patientData);
                }

                if (setPatientDocuments) {
                    let documents = await getData(`companies/${data.companyID}/patients/${patientId}/documents`);

                    documents = documents.sort((a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds);

                    setPatientDocuments(documents);
                }


            } else {
                console.error('No such patient!');
            }
        } else {
            console.error('Failed to fetch companyID');
        }
    } catch (error) {
        console.error('Error fetching companyID:', error);
    }
};