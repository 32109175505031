import React, { useEffect, useState } from 'react';
import { useAuth } from './utils/authContext';
import { useNavigate } from 'react-router-dom';
import { getData, addData, updateData, deleteData, getDocument } from './utils/firestoreService';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';


const PatientsList = () => {
    const { currentUser } = useAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [patients, setPatients] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newPatient, setNewPatient] = useState({
        firstName: '',
        lastName: '',
        dob: ''
    });
    const [editPatient, setEditPatient] = useState(null);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event, patientId) => {
        setAnchorEl(event.currentTarget);
        setSelectedPatientId(patientId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedPatientId(null);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const fetchCompanyIDFromSession = async () => {
        try {
            const response = await fetch('/getCompanyID', {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                return data.companyID;
            } else {
                console.error('Failed to fetch companyID');
                return null;
            }
        } catch (error) {
            console.error('Error fetching companyID:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (currentUser) {
                const companyID = await fetchCompanyIDFromSession();

                if (companyID) {
                    const userInCompanyData = await getDocument(`companies/${companyID}/users`, currentUser.uid);
                    if (userInCompanyData) {
                        setUserInfo(userInCompanyData);
                        const companyData = await getDocument('companies', companyID);
                        if (companyData) {
                            setCompanyInfo(companyData);
                        }

                        const patientsList = await getData(`companies/${companyID}/patients`);
                        setPatients(patientsList);
                    }
                }
            }
        };

        fetchUserInfo();
    }, [currentUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPatient(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddPatient = async () => {
        if (currentUser) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                const newPatientId = await addData(`companies/${companyID}/patients`, {
                    firstName: newPatient.firstName,
                    lastName: newPatient.lastName,
                    dob: newPatient.dob,
                    createdAt: new Date(),
                });

                //this creates a new empty document - but why do I need this?
                await addData(`companies/${companyID}/patients/${newPatientId}/documents`, {
                    createdAt: new Date(),
                    modifiedAt: new Date(),
                });

                setPatients(prevPatients => [
                    ...prevPatients,
                    { id: newPatientId, ...newPatient }
                ]);

                setNewPatient({
                    firstName: '',
                    lastName: '',
                    dob: ''
                });

                setShowForm(false);
            }
        }
    };

    const handleEditPatient = (patient) => {
        setEditPatient(patient);
        setNewPatient({
            firstName: patient.firstName,
            lastName: patient.lastName,
            dob: patient.dob,
        });
        setShowForm(true);
    };

    const handleSaveEditPatient = async () => {
        if (currentUser && editPatient) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                await updateData(`companies/${companyID}/patients`, editPatient.id, {
                    firstName: newPatient.firstName,
                    lastName: newPatient.lastName,
                    dob: newPatient.dob,
                });

                setPatients((prevPatients) =>
                    prevPatients.map((patient) =>
                        patient.id === editPatient.id
                            ? { ...patient, ...newPatient }
                            : patient
                    )
                );

                setEditPatient(null);
                setShowForm(false);
            }
        }
    };

    const handleDeletePatient = async (patientId) => {

        const confirmDelete = window.confirm('Are you sure you want to delete this patient?');
        if (!confirmDelete) return;

        if (currentUser) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                await deleteData(`companies/${companyID}/patients`, patientId);

                setPatients((prevPatients) =>
                    prevPatients.filter((patient) => patient.id !== patientId)
                );
            }
        }
    };

    const handleHistoryClick = (patient) => {
        navigate('/Patient', { state: { patientId: patient.id } });
    };

    const handleNewSessionClick = () => {
        navigate('/newSession', { state: { patientId: selectedPatientId } });
        handleMenuClose();
    };

    const handleDocumentClick = () => {
        navigate('/NewDocument', { state: { patientId: selectedPatientId } });
        handleMenuClose();
    };

    const handleTextClick = () => {
        navigate('/NewText', { state: { patientId: selectedPatientId } });
        handleMenuClose();
    };

    const handlePinClick = async (patientId, currentPinStatus) => {
        if (currentUser) {
            const companyID = await fetchCompanyIDFromSession();

            if (companyID) {
                const newPinStatus = !currentPinStatus;
                await updateData(`companies/${companyID}/patients`, patientId, { pin: newPinStatus });

                setPatients((prevPatients) =>
                    prevPatients.map((patient) =>
                        patient.id === patientId ? { ...patient, pin: newPinStatus } : patient
                    )
                );
            }
        }
    };

    return (
        <div className="page-container">
            <button onClick={handleBack}>&larr;</button>
            <div className="page-title">Patients Page</div>
            {patients.length > 0 ? (
                <table className="standard-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {patients.map((patient, index) => (
                            <tr key={index}>
                                <td onClick={() => handleHistoryClick(patient)} style={{ cursor: 'pointer' }}>
                                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <PushPinIcon
                                            style={{ marginRight: '8px', color: patient.pin ? 'blue' : 'grey' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handlePinClick(patient.id, patient.pin);
                                            }}
                                        />
                                        {patient.firstName} {patient.lastName}
                                    </div>
                                </td>
                                <td className="column-5">
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => handleMenuClick(event, patient.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleMenuClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 48 * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        <MenuItem onClick={handleHistoryClick}>History</MenuItem>
                                        <MenuItem onClick={handleNewSessionClick}>New Session</MenuItem>
                                        <MenuItem onClick={handleDocumentClick}>New Doc</MenuItem>
                                        <MenuItem onClick={handleTextClick}>New Text</MenuItem>
                                        <MenuItem onClick={() => { handleEditPatient(patient); handleMenuClose(); }}>Edit</MenuItem>
                                        <MenuItem onClick={() => { handleDeletePatient(patient.id); handleMenuClose(); }}>Delete</MenuItem>
                                    </Menu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Loading...</p>
            )}
            <button onClick={() => setShowForm(true)}>Add Patient</button>
            {showForm && (
                <div className="add-patient-form">
                    <input
                        type="text"
                        name="firstName"
                        value={newPatient.firstName}
                        onChange={handleInputChange}
                        placeholder="First Name"
                    />
                    <input
                        type="text"
                        name="lastName"
                        value={newPatient.lastName}
                        onChange={handleInputChange}
                        placeholder="Last Name"
                    />
                    <input
                        type="date"
                        name="dob"
                        value={newPatient.dob}
                        onChange={handleInputChange}
                        placeholder="Date of Birth"
                    />
                    <button onClick={editPatient ? handleSaveEditPatient : handleAddPatient}>
                        {editPatient ? 'Save Changes' : 'Save'}
                    </button>
                    <button onClick={() => setShowForm(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default PatientsList;