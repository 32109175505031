import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addData, updateData } from './utils/firestoreService';
import { initializeWebSocket } from './utils/webSocket';
import ProgressBar, { parseProgressFromMessage } from './utils/progressBar';
import { fetchPatientData } from './utils/fetchPatientData';
import { fetchDocumentTypes } from './utils/fetchDocumentTypes';
import { recordingConfirmationMessage } from './globals';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicIcon from '@mui/icons-material/Mic';

const NewSession = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId } = location.state || {};
    const [companyID, setCompanyID] = useState(null);
    const [patientData, setPatientData] = useState({});
    const [patientDocuments, setPatientDocuments] = useState([]);
    const [wsMessages, setWsMessages] = useState([]);
    const [recordingTime, setRecordingTime] = useState(0);
    const socketRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const hasFetchedDataRef = useRef(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [beginProcessing, setBeginProcessing] = useState(false);
    const [processingFail, setProcessingFail] = useState(false);
    const progressBarRef = useRef(null);
    const [volumeLevel, setVolumeLevel] = useState(0);
    const [audioInputDevices, setAudioInputDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [clientId, setClientId] = useState(null);
    const audioStreamRef = useRef(null);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState('');
    const [eta, setEta] = useState(null);
    const [uploadStartTime, setUploadStartTime] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const hasFetchedAudioDevicesRef = useRef(false);

    useEffect(() => {
        if (hasFetchedDataRef.current) return;
    
        if (!patientId) {
            navigate('/patient', { replace: true });
            return;
        }
    
        fetchPatientData(patientId, setCompanyID, setPatientData, setPatientDocuments);
        hasFetchedDataRef.current = true;
    
        if (!hasFetchedAudioDevicesRef.current) {
            fetchAudioInputDevices();
            hasFetchedAudioDevicesRef.current = true;
        }
    
    }, [patientId, navigate]);

    useEffect(() => {
        const defaultId = 0;

        fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setEta, defaultId);
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (uploadStartTime) {
            const timer = setInterval(() => {
                const elapsedTime = Math.floor((Date.now() - uploadStartTime) / 1000);
                progressBarRef.current.update({ time: elapsedTime });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [uploadStartTime]);

    useEffect(() => {
        progressBarRef.current = new ProgressBar('progress-container', eta, isProcessing);
    }, [eta, isProcessing]);

    useEffect(() => {
        if (wsMessages.length > 0) {
            const latestMessage = wsMessages[wsMessages.length - 1];
            const progressData = parseProgressFromMessage(latestMessage);
            if (progressData) {
                progressBarRef.current.update(progressData);
            }
        }
    }, [wsMessages]);

    useEffect(() => {
        const { socket, sendMessage } = initializeWebSocket(setWsMessages, setClientId);
        socketRef.current = socket;
        sendMessage('connected for audio');
        return () => {
            socket.close();
        };
    }, []);

    const handleStreamingConfirm = () => {
        setIsModalOpen(true);
    };

    const handleStartStreaming = async (deviceId) => {
        const newDocData = {
            title: `New Session`,
            description: "Streaming Audio.",
            text: '',
            type: 'session',
            createdAt: new Date(),
            modifiedAt: new Date(),
            documentDate: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
            startTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
            endTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
        };

        let documentId;
        
        try {
            documentId = await addData(`companies/${companyID}/patients/${patientId}/documents`, newDocData);
            setIsStreaming(true);
        } catch (error) {
            console.error('Error creating new document:', error);
            return;
        }

        if (!deviceId) {
            console.error('No audio input devices found');
            return;
        }

        socketRef.current.emit('startStream', clientId);

        socketRef.current.on('connect', () => {
            console.log('Connected to server');
        });

        socketRef.current.on('disconnect', () => {
            console.log('Audio-stream Socket.io connection closed');
            setIsStreaming(false);
            setBeginProcessing(true); 
        });

        socketRef.current.on('error', (error) => {
            console.error('Audio-stream Socket.io error:', error);
            reconnect();
        });
    
        const reconnect = () => {
            setTimeout(() => {
                if (!socketRef.current.connected) {
                    console.log('Attempting to reconnect...');
                    socketRef.current.connect();
                }
            }, 5000); 
        };

        socketRef.current.on('action', async (data) => {
            try {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                if (data.error) {
                    console.error('Error:', data.error);
                } else {
                    if (data.action === 'save') {

                        if (data.type === 'progress') {
                            const updatedDocData = {
                                text: data.processedText,
                                modifiedAt: new Date(),
                                endTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                            };

                            await updateData(`companies/${companyID}/patients/${patientId}/documents`, documentId, updatedDocData);

                        }  else if ((data.type === 'final' && !isProcessing) || beginProcessing) {
                            try {
                                let finalText = data.processedText;
                                const startTime = Date.now();
                                setUploadStartTime(startTime);
                                setIsProcessing(true);
                        
                                const historyFields = ['documentDate', 'modifiedAt', 'case_summary', 'document_summary'];
                                const patientHistory = JSON.parse(JSON.stringify(patientDocuments));
                                
                                Object.keys(patientHistory).forEach(key => {
                                    const document = patientHistory[key];
                                    Object.keys(document).forEach(field => {
                                        if (!historyFields.includes(field)) {
                                            delete document[field];
                                        }
                                    });
                                });

                                const response = await fetch('/processText', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ text: finalText, clientId, docTypeId: selectedDocumentTypeId, patientData, patientHistory })
                                });
                        
                                if (!response.ok) {
                                    throw new Error(`Server error: ${response.status} ${response.statusText}`);
                                }
                        
                                const dataResponse = await response.json();
                                await saveData(patientId, documentId, finalText, dataResponse);
                            } catch (error) {
                                console.error('Error processing text:', error);
                                setIsProcessing(false);
                                setProcessingFail(true);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error parsing data:', error);
            }
        });


        socketRef.current.on('error', (error) => {
            console.error('Audio-stream Socket.io error:', error);
        });

        try {
            console.log(selectedDeviceId)
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: deviceId ? { exact: deviceId } : undefined,
                    sampleRate: 16000
                }
            });

            audioStreamRef.current = stream;

            const audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 16000 });
            const source = audioContext.createMediaStreamSource(stream);
            const processor = audioContext.createScriptProcessor(4096, 1, 1);

            source.connect(processor);
            processor.connect(audioContext.destination);

            processor.onaudioprocess = (e) => {
                const inputBuffer = e.inputBuffer;
                const inputData = inputBuffer.getChannelData(0);
                const pcmData = convertFloat32ToLinear16(inputData);
                if (socketRef.current && socketRef.current.connected) {
                    socketRef.current.emit('audio', pcmData);
                }
            };

            mediaRecorderRef.current = processor;
            volumeMeter(stream);
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };

    const handleStopStreaming = (action = 'stop') => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.disconnect();
            mediaRecorderRef.current = null;
        }

        if (audioStreamRef.current) {
            audioStreamRef.current.getTracks().forEach(track => track.stop());
            audioStreamRef.current = null;
        }

        if (socketRef.current && socketRef.current.connected) {
            const message = { action };
            socketRef.current.emit('action', JSON.stringify(message));
        } else {
            console.error('Socket.io is not connected');
        }

        setIsStreaming(false);

        if (action === 'stop') {
            setBeginProcessing(true);
        }

        if (action === 'cancel') {
            setIsProcessing(false);
        }
    };

    const convertFloat32ToLinear16 = (input) => {
        const output = new Int16Array(input.length);
        for (let i = 0; i < input.length; i++) {
            const s = Math.max(-1, Math.min(1, input[i])); // Clamping
            output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return output;
    };

    const saveData = async (patientId, documentId, processedText, data) => {
        try {
            const updatedDocData = {
                title: selectedDocumentType,
                description: "Audio Session",
                processed_text: processedText,
                type: selectedDocumentType,
                typeId: selectedDocumentTypeId,
                documentDate: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
                endTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                modifiedAt: new Date(),
                ...data
            };

            let docId;
            if (documentId) {
                await updateData(`companies/${companyID}/patients/${patientId}/documents`, documentId, updatedDocData);
                docId = documentId;
            } else {
                docId = await addData(`companies/${companyID}/patients/${patientId}/documents`, updatedDocData);
            }

            navigate('/document', { state: { patientId, docId } });

        } catch (error) {
            console.error('Error saving processed transcript:', error);
        }
    };

    useEffect(() => {
        let timer;
        if (isStreaming) {
            timer = setInterval(() => {
                setRecordingTime(prevTime => {
                    if (prevTime >= 4199) {
                        handleStopStreaming('stop');
                        return prevTime;
                    }
                    return prevTime + 1;
                });
            }, 1000);
        } else {
            setRecordingTime(0);
        }
        return () => clearInterval(timer);
    }, [isStreaming]);

    const volumeMeter = (stream) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.fftSize = 256;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const updateVolume = () => {
            analyser.getByteFrequencyData(dataArray);
            const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
            const volumeLevel = Math.round(volume / 20);

            setVolumeLevel(volumeLevel);

            requestAnimationFrame(updateVolume);
        };
        updateVolume();
    };

    const fetchAudioInputDevices = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });

            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            setAudioInputDevices(audioDevices);

            if (audioDevices.length > 0) {
                setSelectedDeviceId(audioDevices[0].deviceId);
            } else {
                console.warn('No audio input devices found.');
            }
        } catch (error) {
            console.error('Error fetching audio input devices:', error);
        }
    };

    const handleDocumentTypeChange = (e) => {
        const selectedType = documentTypes.find(type => type.name === e.target.value);
        fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setEta, selectedType.id - 1);
    };

    return (
        <div className='page-container'>
            <div className='header-container'>
                <button className='back-button' onClick={handleBack}>&larr;</button>
                <div className='page-title'>
                    <strong>Begin Recording for:</strong> {patientData.firstName} {patientData.lastName}
                </div>
            </div>
            <div className='standard-container'>
                <div className="recording-container">
                    {audioInputDevices.length > 1 && !isStreaming && !isProcessing && (
                        <div className="dropdown-container">
                            <label htmlFor="audio-input-select">Select Microphone:</label>
                            <select
                                id="audio-input-select"
                                value={selectedDeviceId}
                                onChange={(e) => setSelectedDeviceId(e.target.value)}
                            >
                                {audioInputDevices.map(device => {
                                    let shortLabel = device.label || `Microphone ${device.deviceId}`;
                                    if (device.label) {
                                        const match = device.label.match(/\(([^)]+)\)/);
                                        if (match) {
                                            shortLabel = match[1].replace(/^[\d\s\W]+/, '');
                                        }
                                    }
                                    return (
                                        <option key={device.deviceId} value={device.deviceId}>
                                            {shortLabel}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    {Array.isArray(documentTypes) && !isStreaming && !isProcessing && documentTypes.length > 0 && (
                        <div className="dropdown-container">
                            <label htmlFor="documentType" className="dropdown-label">Session Type:</label>
                            <select
                                id="documentType"
                                className="dropdown-select"
                                value={selectedDocumentType}
                                onChange={handleDocumentTypeChange}
                            >
                                {documentTypes.map((type) => (
                                    <option key={type.id} value={type.name}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <button className="recording-button" onClick={() => {
                        if (isStreaming) {
                            handleStopStreaming('stop');
                        } else {
                            handleStreamingConfirm();
                        }
                    }}>
                        {isStreaming ? 'Stop Recording' : 'Start Recording'}
                    </button>


                    {isModalOpen && (
                        <div className="modal" onClick={() => setIsModalOpen(false)}>
                            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                <div className="modal-title">{recordingConfirmationMessage}</div>
                                <div className="modal-button-container">
                                    <button className="button-delete" onClick={() => setIsModalOpen(false)}>
                                        Cancel
                                    </button>
                                    <button className="button-continue" onClick={() => {
                                        setIsModalOpen(false);
                                        handleStartStreaming(selectedDeviceId);
                                    }}>
                                        Agree and Begin Recording
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {(isStreaming || isProcessing) && (
                        <div className="streaming-controls">
                            <button className="cancel-button" onClick={() => handleStopStreaming('cancel')}>
                                Cancel
                            </button>
                            <span className="recording-status">
                                {isStreaming ? `Streaming... ${Math.floor(recordingTime / 60)}m ${recordingTime % 60}s` : `Processing...`}
                            </span>
                        </div>
                    )}

                    {isStreaming && (
                        <div className="volume-meter">
                            {Array.from({ length: 10 }).map((_, index) => (
                                <div key={index} className="volume-bar" style={{ display: index < volumeLevel ? 'block' : 'none' }}></div>
                            ))}
                        </div>
                    )}


                </div>

                {processingFail && (
                    <div>
                        Processing has failed. Please use the "Issues?" button below to report this problem.
                    </div>
                )}

                <div
                    id="progress-container"
                    style={{
                        width: '60%',
                        margin: '4em auto',
                        textAlign: 'center'
                    }}
                ></div>


            </div>
        </div>
    );

};

export default NewSession;