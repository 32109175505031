import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addData, getData, updateData, deleteData, getDocument } from './utils/firestoreService';
import { initializeWebSocket } from './utils/webSocket';
import ProgressBar, { parseProgressFromMessage } from './utils/progressBar';
import { fetchPatientData } from './utils/fetchPatientData';
import { fetchDocumentTypes } from './utils/fetchDocumentTypes';

const NewDocument = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId } = location.state || {};
    const [companyID, setCompanyID] = useState(null);
    const [patientData, setPatientData] = useState({});
    const [patientDocuments, setPatientDocuments] = useState([]); // this doesn't need history just yet
    const [buttonText, setButtonText] = useState('Select Document');
    const [documentTypes, setDocumentTypes] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState('');
    const [eta, setEta] = useState(null);
    const [wsMessages, setWsMessages] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [dropped, setDropped] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);
    const hasFetchedDataRef = useRef(false);
    const progressBarRef = useRef(null);

    useEffect(() => {
        if (hasFetchedDataRef.current) return;

        if (!patientId) {
            navigate('/patient', { replace: true });
            return;
        }

        fetchPatientData(patientId, setCompanyID, setPatientData, setPatientDocuments);
        hasFetchedDataRef.current = true;
    }, [patientId, navigate]);

    useEffect(() => {
        const { socket, sendMessage } = initializeWebSocket(setWsMessages, setClientId);
        sendMessage('connected for NewText');
        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        const defaultId = 1;
        fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setEta, defaultId);
    }, []);

    useEffect(() => {
        progressBarRef.current = new ProgressBar('progress-container', eta);
    }, [eta]);


    useEffect(() => {
        if (wsMessages.length > 0) {
            const latestMessage = wsMessages[wsMessages.length - 1];
            const progressData = parseProgressFromMessage(latestMessage);
            if (progressData) {
                console.log(progressBarRef.current);
                progressBarRef.current.update(progressData);
            }
        }
    }, [wsMessages]);

    const allowedMimeTypes = [
        'application/pdf',
        'image/tiff',
        'image/jpeg',
        'image/tiff',
        'image/jpeg',
        'image/png',
        'image/webp'
    ];

    const getMimeType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf': return 'application/pdf';
            case 'tiff': return 'image/tiff';
            case 'tif': return 'image/tiff';
            case 'jpeg': return 'image/jpeg';
            case 'jpg': return 'image/jpeg';
            case 'png': return 'image/png';
            case 'webp': return 'image/webp';
            default: return null;
        }
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            return;
        }
        setButtonText('Uploading...');
        const mimeType = getMimeType(selectedFile.name);

        if (!mimeType) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('mimeType', mimeType);

        console.log(mimeType);


        
        try {

            const jsonFields = {
                clientId,
                docTypeId: selectedDocumentTypeId + 1,
                patientData
            };

            const response = await fetch('/processDocument', {
                method: 'POST',
                headers: {    
                    'X-JSON-Fields': JSON.stringify(jsonFields)
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                await saveData(patientId, data);

            } else {
                console.error('Failed to upload file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const saveData = async (patientId, data) => {
        try {
            const newDocData = {
                title: `Document`,
                description: "Document Upload.",
                type: 'session',
                type: selectedDocumentType,
                typeId: selectedDocumentTypeId + 1,
                createdAt: new Date(),
                documentDate: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
                startTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                endTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                modifiedAt: new Date(),
                ...data
            };

            const docId = await addData(`companies/${companyID}/patients/${patientId}/documents`, newDocData);

            navigate('/document', { state: { patientId, docId } });

        } catch (error) {
            console.error('Error saving processed transcript:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleDocumentTypeChange = (e) => {
        const selectedType = documentTypes.find(type => type.name === e.target.value);
        setSelectedDocumentType(e.target.value);
        setSelectedDocumentTypeId(selectedType ? selectedType.id : '');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
        setButtonText('Start Upload');
        setDropped(true);
    };

    const handleButtonClick = () => {
        if (!selectedFile) {
            fileInputRef.current.click();
        } else {
            handleFileUpload();
        }
    };

    return (
        <div className='page-container'>
            <button onClick={handleBack}>&larr;</button>
            {patientData && (
                <div className='page-title'>
                    Documents for {patientData.firstName} {patientData.lastName}
                </div>
            )}

            <p>Drag and drop a PDF here. (limit, 15 pages)</p>

            <div
                className={`drop-zone ${dragging ? 'dragging' : ''} ${dropped ? 'dropped' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <p>{dropped ? 'Click Start Upload to continue' : 'Drop files here'}</p>
            </div>

            {Array.isArray(documentTypes) && documentTypes.length > 0 && (
                <div className="dropdown-container">
                    <label htmlFor="documentType" className="dropdown-label">Select Document Type:</label>
                    <select
                        id="documentType"
                        className="dropdown-select"
                        value={selectedDocumentType}
                        onChange={handleDocumentTypeChange}
                    >
                        {documentTypes
                            .filter(type => type.format === 'upload')
                            .map((type) => (
                                <option key={type.id} value={type.name}>
                                    {type.name}
                                </option>
                            ))}
                    </select>
                </div>
            )}

            <div className="upload-container">
                <div id="progress-container"></div>
                <button className="button-add button-larger" onClick={handleButtonClick}>{buttonText}</button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                        setButtonText('Start Upload');
                    }}
                />
            </div>
        </div>
    );
};

export default NewDocument;